import {Component, Input, OnChanges, OnInit} from '@angular/core';
import './banner-mobile.component.scss';

@Component({
  moduleId   : module.id + '',
  selector   : 'banner-mobile',
  templateUrl: 'banner-mobile.component.html',
})

export class BannerMobileComponent {
  @Input() public modules: any;
  public collapse = true;
  public expand = false;

  public showList() {
    this.expand = true;
    this.collapse = false;
  }

  public hideList() {
    this.expand = false;
    this.collapse = true;
  }
}
