import {Observable} from 'rxjs/Observable';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {Entity} from '@synisys/idm-de-core-frontend';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {KbService} from '@synisys/idm-kb-service-client-js';
import {EntityComparisonHelper} from '@synisys/idm-de-core-frontend/app/shared/impl/helper/entity-comparison-helper';
import {ServiceResponse} from '@synisys/idm-de-service-client-js';
import {Location} from '@angular/common';

@Component({
    selector: 'cancel-button',
    template: `
        <button mat-raised-button [id]="id" (click)="onClick()">
            {{ 'de_cancel' | sisTranslateMessage | async }}
        </button>
    `,
})
@ControlMetadata({
    template: `
        <cancel-button
            [id]="'%{id}'"
            [entity]="%{entity}"
            (cancelAction)="%{cancelAction}"
            [systemName]="'%{systemName}'">
        </cancel-button>
    `,
})
export class CancelButtonComponent implements OnInit, OnChanges {
    @Input()
    public id: string;
    @Input()
    public entity: Entity;
    @Input()
    public systemName: string;
    @Input()
    public actionNotifier: Observable<any>;

    @Output()
    public cancelAction: EventEmitter<void> = new EventEmitter<void>();

    protected oldEntity: Entity;

    protected entityComparisonHelper: EntityComparisonHelper;

    constructor(kbService: KbService, public location: Location) {
        this.entityComparisonHelper = new EntityComparisonHelper(kbService);
    }

    public ngOnInit(): void {
        this.oldEntity = this.entity ? this.entity.clone() : null;
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes['entity']) {
            this.actionNotifier &&
                this.actionNotifier.subscribe(
                    data => {
                        this.dataHandler(data);
                    },
                    err => console.error(err)
                );
        }
    }

    public onClick(): void {
        this.cancelAction.emit();
    }

    protected dataHandler(data: any) {
        if (data.key === 1) {
            const serviceResponse: ServiceResponse<Entity> = data.data;
            if (
                serviceResponse &&
                serviceResponse.getMeta() &&
                serviceResponse.getMeta().getValidations().length === 0
            ) {
                this.oldEntity = serviceResponse.getData().clone();
            }
        }
    }
}
