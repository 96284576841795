/* tslint:disable:no-any */
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
} from '@angular/core';
import {DragulaService} from 'ng2-dragula';
import './sis-dynamic-chip-group.component.scss';
/**
 * Chip group controls that will render the passed items as chip list or chip tree.
 * This control will have the possibility to change the order of these elements.
 */

export interface ListItem {
    id: number | string;
    name: string;
    comboItem: any;
}

export interface GroupData {
    items: any[];
    name: string;
}

export interface ComboboxGroup {
    items: {
        name: string;
        value: {
            id: number | string;
        };
        comboItem: any;
    }[];
    name: string;
}

interface Group {
    items: ListItem[];
    name: string;
}

@Component({
    moduleId: module.id,
    selector: 'sis-dynamic-chip-group-combo',
    templateUrl: 'sis-dynamic-chip-group.component.html',
    providers: [DragulaService],
})
export class SisDynamicChipGroupComponent implements OnInit, OnChanges {
    @Input()
    public id: string;
    @Input()
    public placeholder: string;
    @Input()
    public maxCount: number;
    @Input()
    public comboItems: any[];
    @Input()
    public nameField: string;
    @Input()
    public idField: string;
    @Input()
    public comboItemField: string;
    @Input()
    public isHierarchical: boolean;
    @Input()
    public width = 150;
    @Input()
    public indent = 10;
    @Input()
    public disabled: boolean;
    @Output()
    public selectedItemsChange: EventEmitter<ListItem[]> = new EventEmitter<
        ListItem[]
    >();

    @Input()
    public set items(value: any[]) {
        this._items = value.map(item => this.createListItem(item));
    }

    @Input()
    public set data(data: GroupData[]) {
        this._data = data.map((groupData: GroupData) => ({
            items: groupData.items.map((item: any) =>
                this.createListItem(item)
            ),
            name: groupData.name,
        }));
        this.filteredData = this.filterGroupedData();

        if (this._selectedItems) {
            this._draggableItems = this._selectedItems;
        }
    }

    public get draggableItems(): ListItem[] {
        return this._draggableItems;
    }

    public set draggableItems(draggableItems: ListItem[]) {
        this._draggableItems = draggableItems;
    }

    public clickedItemIndex = -1;
    public isComboOpened: boolean;
    public currentValue: number;
    public filteredData: ComboboxGroup[];

    private _draggableItems: ListItem[] = undefined;
    private _data: Group[];
    private _items: ListItem[];
    private _selectedItems: ListItem[];

    public constructor(private dragulaService: DragulaService) {}

    public ngOnInit(): void {
        this.dragulaService.drop.subscribe(() => {
            this.selectedItemsChange.emit(this._draggableItems);
        }, console.log);
    }

    /**
     * This method will handle all input changes
     * @param {SimpleChange} changes
     * @returns {void}
     * @public
     * @method
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['items'] && this._selectedItems !== this._items) {
            this._selectedItems = this._items;
            this._draggableItems = this._selectedItems;
        }
    }

    public delete(id: any): void {
        this._items = this._selectedItems.filter(item => {
            return item[this.idField] !== id;
        });
        this._selectedItems = this._items;

        this.selectedItemsChange.emit(this._selectedItems);
        this._draggableItems = this._draggableItems.filter((value: any) => {
            return value[this.idField] !== id;
        });
    }

    public click(index: number, id: any): void {
        this.clickedItemIndex = index;
        this.currentValue = id;
        this.filteredData = this.filterGroupedData();

        this.isComboOpened = true;
    }

    public closeCombobox(isClosed: boolean): void {
        if (isClosed) {
            this.isComboOpened = false;
        }
    }

    public setValue(value: any): void {
        if (value) {
            const selectedValuesIds: (
                | number
                | string
            )[] = this._selectedItems.map((item: ListItem) => item.id);
            const selectedValues: ListItem[] = this._selectedItems.slice();
            const path: ListItem = {
                id: value.value.id,
                name: value.name,
                comboItem: value.comboItem,
            };

            const selectedIndex: number = this.currentValue
                ? selectedValuesIds.indexOf(this.currentValue)
                : selectedValuesIds.indexOf(path.id);
            selectedIndex !== -1
                ? (selectedValuesIds[selectedIndex] = path.id)
                : selectedValuesIds.push(path.id);
            selectedIndex !== -1
                ? (selectedValues[selectedIndex] = path)
                : selectedValues.push(path);

            this._items = selectedValues;
            this._selectedItems = this._items;
            this.selectedItemsChange.emit(this._selectedItems);

            this._draggableItems = selectedValues;
        }
    }

    public onComboItemChange(comboItem: any, itemId: number | string): void {
        if (comboItem) {
            const itemChanged = this._selectedItems.find(
                (item: ListItem) => item.id === itemId
            );
            if (itemChanged) {
                itemChanged.comboItem = comboItem;
                this._draggableItems = this._selectedItems;
                this.selectedItemsChange.emit(this._selectedItems);
            }
        }
    }

    private createListItem(value: any): ListItem {
        return {
            id: value[this.idField],
            name: value[this.nameField],
            comboItem: value[this.comboItemField],
        };
    }

    private filterGroupedData(): ComboboxGroup[] {
        if (this.currentValue) {
            this._selectedItems = this._selectedItems.filter(
                (item: ListItem) => {
                    return item.id !== this.currentValue;
                }
            );
        }
        return this._data
            .map((group: Group) => ({
                name: group.name,
                items: group.items
                    .filter(
                        (item: ListItem) =>
                            !this._selectedItems ||
                            !this._selectedItems.some(
                                (selectedItem: ListItem) =>
                                    item.id === selectedItem.id
                            )
                    )
                    .map((item: ListItem) => ({
                        name: item.name,
                        value: {
                            id: item.id,
                        },
                        comboItem: item.comboItem,
                    })),
            }))
            .filter((group: any) => {
                return group.items.length > 0;
            });
    }
}
