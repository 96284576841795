/**
 * @author Vahagn Lazyan.
 * @since 2.2.0
 */
import {Component} from '@angular/core';

@Component({
             moduleId   : module.id + '',
             selector   : 'cancel-warning-popup',
             templateUrl: 'cancel-warning-popup.component.html',
           })
export class CancelWarningPopupComponent {
}
