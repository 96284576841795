import {Subscription} from 'rxjs/Subscription';
import {set, values} from 'lodash';

export class AsyncStateManager {
  private _readinessProbes: { [key: string]: boolean } = {};
  private _subscriptions: { [key: string]: Subscription } = {};

  public addSubscription(key: string, subscription: Subscription, alwaysReady = false) {
    if (!this._readinessProbes.hasOwnProperty(key)) {
      this._readinessProbes = set(this._readinessProbes, key, alwaysReady);
    }
    this._subscriptions = set(this._subscriptions, key, subscription);
  }

  public setReady(key) {
    this._readinessProbes = set(this._readinessProbes, key, true);
  }

  public setUnReady(key) {
    this._readinessProbes = set(this._readinessProbes, key, false);
  }

  public isReady(): boolean {
    return values(this._readinessProbes).reduce((reduction, value) => reduction && value);
  }

  public destroy() {
    values(this._subscriptions).forEach(subscription => subscription.unsubscribe());
    this._readinessProbes = undefined;
    this._subscriptions = undefined;
  }
}
